$(document).ready(function () {
    


    //slider hpmepage - triangle
    $('.triangleSlider').owlCarousel({
        items:1,
        loop:true,
        autoplay:true,
        autoplayTimeout:5000,
        smartSpeed:1800,
        nav:false,
        dots:false,
        //navText: ["<img src='img/left_icon_slider.png'>","<img src='img/right_icon_slider.png'>"]  //wylaczenie prawo/lewo
    })
 

  
    lightbox.option({
      'resizeDuration': 200,
      'wrapAround': true
    })



    $('.sliderTrusted').owlCarousel({
      loop:true,
      items:1,
      autoplay:true,
      autoplayTimeout:5000,
      smartSpeed:1800,
      margin:10,
      responsiveClass:true,
      dots:false,
      navText: [$('.owl-left'),$('.owl-right')],
      responsive:{
          0:{
              items:1,
              nav:true
          },
          600:{
              items:3,
              nav:false
          },
          1000:{
              items:5,
              nav:true,
              loop:false
          }
      }
  })



});

